import './App.css';
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';

const Story = () => {
    const navigate = useNavigate();
    const [isRunning, setIsRunning] = useState(false);    
    const apiUrl = process.env.REACT_APP_API_URL;
    const [story, setStory] = useState(null);    
    const [currentPart, setCurrentPart] = useState(0);
    
    const music = useRef(null);
    const voice = useRef(null);
    const timeout = useRef(null);
    let part = 0;
    
    useEffect(() => {  
        fetch(`${apiUrl}/api/story/retrieve`, {
            method: 'GET'
        })
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                return [];
            }
        })
        .then(data => {
            setStory(data);
        })      
        .catch(error => {

        });
        
        
     }, []);

    const startStory = () => { 
        setIsRunning(true);
      
        if (music.current === null) {
            music.current = new Audio(story.music);
            music.current.volume = 0.2;
            music.current.play();   
        }
        
        if (part >= story.parts.length) {
            music.current.volume = 0.15;
            setTimeout(() => {
                music.current.volume = 0.1;
            }, 1000);
            setTimeout(() => {
                music.current.volume = 0.05;
            }, 2000);
            setTimeout(() => {
                music.current.pause();
            }, 3000);
            return;
        }
        
        setCurrentPart(prevPart => prevPart + 1);
        voice.current = new Audio(story.parts[part].voice);
        part = part + 1;
        
        voice.current.addEventListener('loadedmetadata', () => {
            timeout.current= setTimeout(() => {
                startStory();
            }, voice.current.duration * 1000);
        });
        voice.current.play();
        scrollToEnd();
    };

    
    const scrollToEnd = () => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth'
        });
    };
    
    const pauseStory = () => {  
        setIsRunning(false);
        if (music.current) {
            music.current.pause();
            music.current = null;
        }
        if (voice.current) {
            voice.current.pause();
            voice.current = null;
        }
        
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
        
        setCurrentPart(0);
        part = 0;
    };
     
    return (
        <div className="content">
            <div className="user-info">
                <h2>Credible Stories</h2>
                <button type="button" className="red-button" onClick={() => navigate('/')}>Login</button>
            </div>
            
            <div className="content-group">
                { isRunning ?
                <button onClick={pauseStory}>Stop</button>
                :
                <button disabled={!story} onClick={startStory}>{!story ? 'Loading...' : 'Start Adventure'}</button>
                }
            </div>
            
            { story &&
            <div className="story">
                <h2 className='fade-in visible'>{story.title}</h2>
                {story.parts.map((item, index) => (
                    index <= currentPart &&
                    <div className={`fade-in ${index < currentPart ? 'visible' : ''}`} key={index}>
                        <img className='story-image' src={item.image}/>
                        <p>{item.text}</p>    
                    </div>
                ))}
            </div>
            }
        </div>
    );
};

export default Story;
